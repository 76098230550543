
import { defineComponent, ref, reactive, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const state = reactive({
      codeArk :"",
      codeArkError : '',
      codeArkSociete : '',
      loaderEnabled: true,
    });
    
    let username = "";
    let password = "";
    
    store.dispatch(Actions.LOGOUT);

    window.localStorage.setItem('filterBordereau', "");
    window.localStorage.setItem('currentBord', "");
    window.localStorage.setItem('currentBordType', "");

    const submitButton = ref<HTMLElement | null>(null);

    const formLogin = ref<HTMLFormElement | null>(null);


    onMounted(async () => {

      if (state.codeArk == "") {
        state.codeArkError = "Le code Ark est obligatoire"
        return false;
      }
      
      if(router.currentRoute.value.params.redirect == "password" && router.currentRoute.value.params.token) router.push({ name: "loader", params: { redirect: "password", token: router.currentRoute.value.params.token }});
      fCodeArk();
    });

    const fCodeArk = async () => {
      
      const rCodeArk = await getAxios("/getCodeArk/" + state.codeArk);
      if (rCodeArk.results == 'KO') {
        state.codeArkError = "Le code Ark est inconnu, veuillez le vérifier svp."
        return false;
      }
      
      window.localStorage.setItem('codeArk', state.codeArk);

      state.codeArkSociete = rCodeArk.results;
      const parametrage = store.getters.getParams;
      if(parametrage?.par_c_nom_service) state.codeArkSociete = parametrage.par_c_nom_service;

      state.loaderEnabled = false;

    }


    //Create form validation object
    const login = Yup.object().shape({
      username: Yup.string()
        .email("L'identifiant doit être un email valide")
        .required("L'identifiant est obligatoire")
        .label("username"),
      password: Yup.string()
        .required("Le mot de passe est obligatoire")
        .label("Password"),
    });



    //Form submit function
    const onSubmitLogin = async (values) => {

      store.dispatch(Actions.LOGOUT);
      
      // Clear existing errors
      //store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.LOGIN, values)
          .then(() => {
            Swal.fire({
              title: store.getters.currentUser.societeCodeArk ? "Connexion établie : " + store.getters.currentUser.societeCodeArk : "Connexion établie !", 
              text:
                "Bienvenue " +
                store.getters.currentUser.uti_c_prenom +
                " " +
                store.getters.currentUser.uti_c_nom +
                " sur espace Service versant !",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Accèder à Service versant",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              
              if (router.currentRoute.value.params.redirect) {
                const stringRec = (router.currentRoute.value.params.redirect) as string;
                const token = router.currentRoute.value.params.token
                if(token) {
                  router.push({ name: "loader", params: {redirect: stringRec, token: token} });
                }else{
                  router.push({ name: stringRec });
                }
              } else {

                store.dispatch(Actions.SET_CURRENT_BORD, {
                  bordSeq: 0,
                  bordType : ''
                });

                router.push({ name: "board" });
              }

            });
          })
          .catch(() => {
            Swal.fire({
              text: "Il semble y avoir une erreur, veuillez vérifier vous identifiants de connexion.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Retourner sur la page de connexion",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 500);

    

    };

    if (router.currentRoute.value.params.codeArk) {
      const stringRec = (router.currentRoute.value.params.codeArk) as string;
      state.codeArk = stringRec;
    } else {

      const mCodeArk = window.localStorage.getItem('codeArk');
      // console.log("mCodeArk ->", mCodeArk);
      if (mCodeArk) {
        router.push({ name: "sign-in", params: { 'codeArk': mCodeArk } })
        state.codeArk = mCodeArk;
        fCodeArk();
      } 

    }

    return {
      onSubmitLogin,
      login,
      submitButton,
      state,
      username,
      password
    };
  },
});
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
