<template>
  <!--begin::Wrapper-->
  <div
    class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto"
    style="background-color: #fffffff7"
  >
    <!--begin::Form-->
    <Form
      class="form w-100"
      id="kt_login_signin_form"
      ref="formLogin"
      @submit="onSubmitLogin"
      :validation-schema="login"
    >
      <!--begin::Heading-->
      <div class="text-center mb-10">
        <img alt="Logo" src="media/logos/logo_arkheia_rvb.png" class="h-65px" />
        <!--end::Title-->
      </div>
      <!--begin::Heading-->
      <!--
      <div class="mb-10 bg-light-info p-8 rounded">
        <div class="text-info">
          Mode Démo, utiliser le login : <strong>demo@demo.com</strong> et le
          mot de passe <strong>alicia</strong> pour continuer.
        </div>
      </div>
      -->
      <!--begin::Input group-->
      
      <div class="fv-row mb-10 p-2" style="background: #f3f0ec; text-align: center;">
        
        <label class="form-label fs-4 fw-bolder text-dark" style="text-transform: uppercase;" v-if="!state.codeArkError">{{ state.codeArkSociete }}</label>
        <div class="fv-plugins-message-container" v-if="state.codeArkError">
          <div class="fv-help-block fs-5">
            <b>{{ state.codeArkError }}</b>
          </div>
        </div>
      </div>
      
      <div class="fv-row mb-10" v-show="!state.loaderEnabled">
        
        <!--begin::Label-->
        <label class="form-label fs-6 fw-bolder text-dark">Identifiant</label>
        <!--end::Label-->

        <!--begin::Input-->
        <Field
          class="form-control form-control-lg form-control-solid"
          type="text"
          name="username"
          v-model="username"
          autocomplete="off"
        />
        <!--end::Input-->
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="username" />
          </div>
        </div>
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="fv-row mb-10"  v-show="!state.loaderEnabled">
        <!--begin::Wrapper-->
        <div class="d-flex flex-stack mb-2">
          <!--begin::Label-->
          <label class="form-label fw-bolder text-dark fs-6 mb-0"
            >Mot de passe</label
          >
          <!--end::Label-->

          <!--begin::Link-->
          <router-link to="/password-reset" class="link-primary fs-6 fw-bolder">
            Mot de passe oublié ?
          </router-link>
          <!--end::Link-->
        </div>
        <!--end::Wrapper-->

        <!--begin::Input-->
        <Field
          class="form-control form-control-lg form-control-solid"
          type="password"
          name="password"
          autocomplete="off"
          v-model="password"
        />
        <!--end::Input-->
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="password" />
          </div>
        </div>
      </div>
      <!--end::Input group-->

      <!--begin::Actions-->
      <div class="text-center"  v-show="!state.loaderEnabled">
        <!--begin::Submit button-->
        <button
          type="submit"
          ref="submitButton"
          id="kt_sign_in_submit"
          class="btn btn-lg btn-primary w-100 mb-5"
        >
          <span class="indicator-label"> Se connecter </span>

          <span class="indicator-progress">
            Chargement...
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
        <!--end::Submit button-->
      </div>
      <!--end::Actions-->
    </Form>
    <!--end::Form-->
  </div>
  <!--end::Wrapper-->
</template>

<script lang="ts">
import { defineComponent, ref, reactive, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const state = reactive({
      codeArk :"",
      codeArkError : '',
      codeArkSociete : '',
      loaderEnabled: true,
    });
    
    let username = "";
    let password = "";
    
    store.dispatch(Actions.LOGOUT);

    window.localStorage.setItem('filterBordereau', "");
    window.localStorage.setItem('currentBord', "");
    window.localStorage.setItem('currentBordType', "");

    const submitButton = ref<HTMLElement | null>(null);

    const formLogin = ref<HTMLFormElement | null>(null);


    onMounted(async () => {

      if (state.codeArk == "") {
        state.codeArkError = "Le code Ark est obligatoire"
        return false;
      }
      
      if(router.currentRoute.value.params.redirect == "password" && router.currentRoute.value.params.token) router.push({ name: "loader", params: { redirect: "password", token: router.currentRoute.value.params.token }});
      fCodeArk();
    });

    const fCodeArk = async () => {
      
      const rCodeArk = await getAxios("/getCodeArk/" + state.codeArk);
      if (rCodeArk.results == 'KO') {
        state.codeArkError = "Le code Ark est inconnu, veuillez le vérifier svp."
        return false;
      }
      
      window.localStorage.setItem('codeArk', state.codeArk);

      state.codeArkSociete = rCodeArk.results;
      const parametrage = store.getters.getParams;
      if(parametrage?.par_c_nom_service) state.codeArkSociete = parametrage.par_c_nom_service;

      state.loaderEnabled = false;

    }


    //Create form validation object
    const login = Yup.object().shape({
      username: Yup.string()
        .email("L'identifiant doit être un email valide")
        .required("L'identifiant est obligatoire")
        .label("username"),
      password: Yup.string()
        .required("Le mot de passe est obligatoire")
        .label("Password"),
    });



    //Form submit function
    const onSubmitLogin = async (values) => {

      store.dispatch(Actions.LOGOUT);
      
      // Clear existing errors
      //store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.LOGIN, values)
          .then(() => {
            Swal.fire({
              title: store.getters.currentUser.societeCodeArk ? "Connexion établie : " + store.getters.currentUser.societeCodeArk : "Connexion établie !", 
              text:
                "Bienvenue " +
                store.getters.currentUser.uti_c_prenom +
                " " +
                store.getters.currentUser.uti_c_nom +
                " sur espace Service versant !",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Accèder à Service versant",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              
              if (router.currentRoute.value.params.redirect) {
                const stringRec = (router.currentRoute.value.params.redirect) as string;
                const token = router.currentRoute.value.params.token
                if(token) {
                  router.push({ name: "loader", params: {redirect: stringRec, token: token} });
                }else{
                  router.push({ name: stringRec });
                }
              } else {

                store.dispatch(Actions.SET_CURRENT_BORD, {
                  bordSeq: 0,
                  bordType : ''
                });

                router.push({ name: "board" });
              }

            });
          })
          .catch(() => {
            Swal.fire({
              text: "Il semble y avoir une erreur, veuillez vérifier vous identifiants de connexion.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Retourner sur la page de connexion",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 500);

    

    };

    if (router.currentRoute.value.params.codeArk) {
      const stringRec = (router.currentRoute.value.params.codeArk) as string;
      state.codeArk = stringRec;
    } else {

      const mCodeArk = window.localStorage.getItem('codeArk');
      // console.log("mCodeArk ->", mCodeArk);
      if (mCodeArk) {
        router.push({ name: "sign-in", params: { 'codeArk': mCodeArk } })
        state.codeArk = mCodeArk;
        fCodeArk();
      } 

    }

    return {
      onSubmitLogin,
      login,
      submitButton,
      state,
      username,
      password
    };
  },
});
export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>
